import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import {
  ImportIcon,
  EditIcon,
  IconExpandArrow,
  AddIcon,
  IconFileDownload,
} from "assets/icons";
import Filter from "ui/FilterUser";
import { BasicButton } from "ui/Buttons";

import FormCheck from "react-bootstrap/FormCheck";
import styled from "styled-components";
import {
  Form,
  InputwithLabel,
  Formik,
  SimpleSelect,
  DatePickerField,
  TimePickerField,
  Radio,
  RadioBlue,
  ErrorMessage,
} from "ui/forms";
import Checkbox from "ui/Checkbox";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import * as Yup from "yup";
import Card from "ui/Card";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";

import "react-datepicker/dist/react-datepicker.css";
import Table from "ui/table";

import ToggleButtonComponent from "ui/ToggleButton";
import { withRouter, useHistory } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import axios from "axios";
import Loader from "ui/Loader";
//import { resolve } from "core-js/fn/promise";
import { useDepartments } from "queries/departments";
import { useUsers } from "queries/users";
import ErrorHandlingComponent from "ui/ErrorHandingComponent";
import { useCreateUser } from "mutations/createUser";
import { useUpdateUser } from "mutations/updateUser";
import { useImportUser } from "mutations/importUser";
import EditUserModal from "./EditUserModal";
import AddUserModal from "./AddUserModal";
import { importCSV, downloadSampleImportFile } from "services/user.service";
import Tooltip from "ui/Tooltip";
import useDebounce from "hooks/useDebounce";
import { duration } from "moment";
import { Authorise } from "helpers/Authorization";
import { forFutureRelease } from "utils/Configs";
import { useRoles } from "queries/profile";

const TopSection = styled.div`
  .download {
    text-decoration: underline;
    font-family: Montserrat Alternates;
    font-size: 12px;
    line-height: 15px;
    color: #909090;
    cursor: pointer;
  }
`;

const Counter = styled.div`
  background-color: #33c2df;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  display: inline-block;
  color: white;
  position: relative;
  top: 5px;
`;

const StyledTable = styled.div`
  .table-wrapper {
    padding: 25px 0px;
  }
`;

const StyledFilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Import = styled.div`
  width: 100px;
  height: 40px;
  background: #3a3b48 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #00000017;
  border: 1px solid #707070;
  border-radius: 20px;
  font-family: Montserrat Alternates-semiBold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 12px;
  /* margin: 0px 10px; */
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

export default function UserListing() {
  let history = useHistory();

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [currentSelectedUser, setCurrentSelectUser] = useState(null);

  const { data: roleData } = useRoles();
  const userRolesData = roleData?.data;

  const [page, setPage] = useState(0);
  const [initialPage, setinitialPage] = useState(0);
  const [filterData, setFilterData] = useState({
    username: "",
    statusArray: [],
    roleArray: [],
    businessArray: [],
    sortOn: "createdAt",
    sortOrder: "desc",
  });

  const debouncedfilterData = useDebounce(filterData, 500);

  const [errorMessage, setErrorMessage] = useState("");
  const userAdd = useCreateUser();
  const userUpdate = useUpdateUser();
  const queryClient = useQueryClient();
  const importUser = useImportUser();

  const {
    data: users,
    isLoading,
    refetch,
  } = useUsers(page, debouncedfilterData);
  const { data: departments } = useDepartments();

  const tableData = users ? users.rows : [];
  const TotalRowsCount = users ? users.count : "";

  const updateTableData = (updatedFilterData) => {
    setPage(0);
    setinitialPage(0);
    setFilterData({ ...filterData, ...updatedFilterData });
  };
  const handleImport = (event) => {
    let file = new FormData();
    file.append("file", event.currentTarget.files[0]);
    importUser
      .mutateAsync(file)
      .then(() => {
        window.flash(
          "open",
          "primary",
          "User imported successfully",
          true,
          3000
        );
        refetch();
        //setErrorMessage("User imported successfully");
      })
      .catch(function (error) {
        if (error.response) {
          window.flash(
            "open",
            "danger",
            `${error.response.data.message}`,
            true,
            3000
          );
          //setErrorMessage(error.response.data.message);
        }
      });
  };
  const onAddUserModalHide = () => {
    setShowAddUserModal(false);
  };

  const onEditUserModalHide = () => {
    setShowEditUserModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "First name",
        accessor: "givenName",
        id: "givenName",
        maxWidth: 100,
      },
      {
        Header: "Last name",
        accessor: "familyName",
        id: "familyName",
        maxWidth: 100,
      },
      {
        Header: "Mobile",
        accessor: "phoneNumber",
        id: "phoneNumber",
      },
      {
        Header: "Email",
        accessor: "email",
        id: "email",
        maxWidth: 150,
      },
      {
        Header: "User Permission",
        accessor: "role",
        id: "role",
        Cell: (props) => (
          <>
            <Capitalize>
              {props.row.values.role == "simplicollect"
                ? "SimpliCollect"
                : props.row.values.role == "simplipay"
                ? "SimpliPay":props.row.values.role == 'SimpliCollect&Simplipay'?"SimpliCollect and SimpliPay"
                : props.row.values.role}
            </Capitalize>
          </>
        ),
      },

      /* businesses are hidden for current development */
      {
        Header: "Businesses",
        accessor: "business",
        id: "business",
        show: forFutureRelease,
        // disableSortBy: true,
        Cell: (props) => (
          <>
            {props.row.values.business.map((department) => (
              <>
                <div>
                  <span style={{ marginBottom: "0px" }}>{department.name}</span>
                  <IconExpandArrow font-size="7px" />
                  <Counter data-tip={department.fullyQualifiedName}>
                    <span
                      style={{
                        position: "absolute",
                        left: "2px",
                        bottom: "-5px",
                      }}
                    >
                      {department.fullyQualifiedName.split(":").length - 1 > 0
                        ? `+${
                            department.fullyQualifiedName.split(":").length - 1
                          }`
                        : department.fullyQualifiedName.split(":").length}
                    </span>
                  </Counter>
                </div>
                <Tooltip place="bottom" />
              </>
            ))}
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        id: "status",
        Cell: (props) =>
          props.row.values.status === "active" ? (
            <Capitalize style={{ color: "#00CE7F" }}>
              {props.row.values.status}
            </Capitalize>
          ) : (
            <Capitalize>In Active</Capitalize>
          ),
      },
      {
        Header: "Action",
        id: "action",
        disableSortBy: true,
        Cell: (props) => (
          <>
            <Authorise
              showUnAuthorisedComponenet={<></>}
              allowedOperation="read-write"
              allowedPermission="SETTINGS_UM"
              allowedRoles={["admin", "read-write"]}
            >
              <EditIcon
                onClick={() => {
                  setCurrentSelectUser(props.row.original);
                  setShowEditUserModal(true);
                }}
                font-size="18px"
              />
            </Authorise>
          </>
        ),
      },
    ],
    []
  );

  const rowClick = (row) => {
    return null;
  };
  const onHeaderClick = (column) => {
    filterData.sortOn = column.id;
    filterData.sortOrder = filterData.sortOrder === "desc" ? "asc" : "desc";
    setFilterData({ ...filterData, ...filterData });
  };

  const handlePageChange = (page) => {
    setPage(page);
    setinitialPage(page);
  };

  return (
    <>
      <ErrorHandlingComponent errors={errorMessage} />
      <TopSection>
        <Row>
          <Col>
            {" "}
            <p className="HeadingText">Add Users and assign Roles to them</p>
          </Col>
          <Col>
            <StyledFilterSection>
              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedRoles={["admin"]}
              >
                <>
                  <IconFileDownload font-size="20px" />
                  <a
                    href={downloadSampleImportFile()}
                    style={{ marginLeft: "5px" }}
                  >
                    <span className="download">
                      Download sample <br /> Import File
                    </span>
                  </a>

                  <label
                    style={{ cursor: "pointer", margin: "0px 10px" }}
                    htmlFor="fileUpload"
                  >
                    <Import>
                      <ImportIcon font-size="14px" />
                      <span style={{ marginLeft: "5px" }}>Import</span>
                      <input
                        hidden
                        id="fileUpload"
                        type="file"
                        accept=".csv"
                        onChange={handleImport}
                      ></input>
                    </Import>
                  </label>
                </>
              </Authorise>
              <Filter handleFilterData={updateTableData} userRolesData={userRolesData}/>
              <Authorise
                showUnAuthorisedComponenet={<></>}
                allowedOperation="read-write"
                allowedPermission="SETTINGS_UM"
                allowedRoles={["admin", "read-write"]}
              >
                <BasicButton
                  type="button"
                  width="110px"
                  onClick={() => {
                    setShowAddUserModal(true);
                  }}
                >
                  Add User
                </BasicButton>
              </Authorise>
            </StyledFilterSection>
          </Col>
        </Row>
      </TopSection>
      <Row>
        <Col>
          <StyledTable>
            <Table
              isloading={isLoading}
              data={tableData}
              columns={columns}
              totalCount={TotalRowsCount}
              rowClick={rowClick}
              handlePageChange={handlePageChange}
              initialPage={initialPage}
              onHeaderClick={onHeaderClick}
            />
          </StyledTable>
        </Col>
      </Row>
      <AddUserModal
        show={showAddUserModal}
        onHide={onAddUserModalHide}
        refetch={refetch}
        userRolesData={userRolesData}
      />
      {currentSelectedUser && (
        <EditUserModal
          show={showEditUserModal}
          user={currentSelectedUser}
          onHide={onEditUserModalHide}
          refetch={refetch}
          userRolesData={userRolesData}
        />
      )}
    </>
  );
}
