import React from 'react';
import { useState } from 'react';
import {
  Button, Col, Form, Row,
} from "react-bootstrap";
import state from "assets/state.json";
import { useUpdateCustomerBillingDetails } from 'mutations/updateCustomers';
import { Route_URLs } from 'routesURLs';
import { useHistory } from "react-router-dom";

const BillingAddress = () => {
  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(false);
  const billingDetailsUpdate = useUpdateCustomerBillingDetails();
  let customerid = sessionStorage.getItem("username");
  const [formData, setFormData] = useState({
    givenName: "",
    familyName: "",
    address: "",
    city: "",
    zip: "",
    state: "",
    phone: ""
  });
  const [errors, setErrors] = useState({
    givenName: '',
    familyName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormData({
      ...formData,
      [id]: value
    });

    switch (id) {
      case 'givenName':
        setErrors({
          ...errors,
          givenName: validateGivenName(value).message
        });
        break;
      case 'familyName':
        setErrors({
          ...errors,
          familyName: validateFamilyName(value).message
        });
        break;
      case 'address':
        setErrors({
          ...errors,
          address: validateAddress(value).message
        });
        break;
      case 'city':
        setErrors({
          ...errors,
          city: validateCity(value).message
        });
        break;
      case 'state':
        setErrors({
          ...errors,
          state: validateState(value).message
        });
        break;
      case 'zip':
        setErrors({
          ...errors,
          zip: validateZip(value).message
        });
        break;
      case 'phone':
        setErrors({
          ...errors,
          phone: validatePhone(value).message
        });
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      givenName,
      familyName,
      address,
      city,
      state,
      zip,
      phone
    } = formData;

    const givenNameValidationResult = validateGivenName(givenName);
    const familyNameValidationResult = validateFamilyName(familyName);
    const addressValidationResult = validateAddress(address);
    const cityValidationResult = validateCity(city);
    const stateValidationResult = validateState(state);
    const zipValidationResult = validateZip(zip);
    const phoneValidationResult = validatePhone(phone);

    setErrors({
      givenName: givenNameValidationResult.message,
      familyName: familyNameValidationResult.message,
      address: addressValidationResult.message,
      city: cityValidationResult.message,
      state: stateValidationResult.message,
      zip: zipValidationResult.message,
      phone: phoneValidationResult.message
    });

    const isValid =
      givenNameValidationResult.valid &&
      familyNameValidationResult.valid &&
      addressValidationResult.valid &&
      cityValidationResult.valid &&
      stateValidationResult.valid &&
      zipValidationResult.valid &&
      phoneValidationResult.valid;

    setValidated(isValid);

    if (isValid) {
      setLoader(true);
      let dataset = { ...formData, customerid: customerid };
      try {
        let updatedata = await billingDetailsUpdate
          .mutateAsync(dataset)
       


        if (updatedata.data.isPaymentLinkEnable?.message == "Success") {
     
      
          window.location.replace(Route_URLs.dashboard_customer);
        }

        setLoader(false);
        setEdit(false);
      } catch (e) {


      }

    } else {
      setEdit(true);

    }
  };

  const validateGivenName = (name) => {
    return {
      valid: name.trim().length > 0,
      message: name.trim().length > 0 ? '' : 'Given name is required'
    };
  };

  const validateFamilyName = (name) => {
    return {
      valid: name.trim().length > 0,
      message: name.trim().length > 0 ? '' : 'Family name is required'
    };
  };

  const validateAddress = (address) => {
    return {
      valid: address.trim().length > 0,
      message: address.trim().length > 0 ? '' : 'Address is required'
    };
  };

  const validateCity = (city) => {
    return {
      valid: city.trim().length > 0,
      message: city.trim().length > 0 ? '' : 'City is required'
    };
  };

  const validateState = (state) => {
    return {
      valid: state.trim().length > 0,
      message: state.trim().length > 0 ? '' : 'State is required'
    };
  };

  const validateZip = (zip) => {
    if (!zip) {
      return {
        valid: false,
        message: 'Zip code is required'
      };
    } else {
      return {
        valid: true,
        message: ''
      };
    }


  };

  const validatePhone = (phone) => {
    if (!phone) {
      return {
        valid: false,
        message: 'Phone is required'
      };
    }
    const regex = /^[0-9]{10}$/;
    return {
      valid: regex.test(phone),
      message: regex.test(phone) ? '' : 'Invalid phone number'
    };
  };



  return (
    <div style={{ display: "flex", justifyContent: "center", backgroundImage: "url(/static/media/Banner-bg.a71507f6.jpg)", backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "100vh" }}>
      <div className='billingFormWrapp'>
        <h1 style={{ color: "#909090", fontFamily: "Montserrat Alternates-Bold", fontSize: "23px", marginBottom: "30px" }}>Billing Address</h1>
        <Form
          className="billing_form_outer"
          style={{ color: "white" }}
          onSubmit={handleSubmit}
          noValidate validated={validated}
        >

          <Row >
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="givenName">
                <Form.Control
                  className="billingForm"
                  required
                  type="text"
                  value={formData.givenName}
                  placeholder="Given Name"
                  onChange={handleChange}
                />
                {errors.givenName && <Form.Text className="text-danger">{errors.givenName}</Form.Text>}
              </Form.Group>
            </Col>

            <Col xs={6}>
              <Form.Group className="mb-3" controlId="familyName">
                <Form.Control
                  required
                  className="billingForm"
                  type="text"
                  value={formData.familyName}
                  placeholder="Family Name"
                  onChange={handleChange}
                />
                {errors.familyName && <Form.Text className="text-danger">{errors.familyName}</Form.Text>}
              </Form.Group>
            </Col>
          </Row>

          <Row >
            <Col>
              <Form.Group className="mb-3" controlId="city">
                <Form.Control
                  required
                  className="billingForm"
                  type="text"
                  value={formData.city}
                  placeholder="City"
                  onChange={handleChange}
                />
                {errors.city && <Form.Text className="text-danger">{errors.city}</Form.Text>}
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3" controlId="address">
                <Form.Control
                  required
                  className="billingForm"
                  type="text"
                  value={formData.address}
                  placeholder="Address"
                  onChange={handleChange}
                />
                {errors.address && <Form.Text className="text-danger">{errors.address}</Form.Text>}
              </Form.Group>
            </Col>
          </Row>

          <Row >
            <Col>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Control
                  required
                  className="billingForm"
                  type="text"
                  value={formData.phone}
                  placeholder="Phone"
                  onChange={handleChange}
                />
                {errors.phone && <Form.Text className="text-danger">{errors.phone}</Form.Text>}
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3" controlId="zip">
                <Form.Control
                  required
                  className="billingForm"
                  type="number"
                  value={formData.zip}
                  placeholder="Zip Code"
                  onChange={handleChange}
                  min="10000"
                  max="99999"
                />
                {errors.zip && <Form.Text className="text-danger">{errors.zip}</Form.Text>}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="state">
                <Form.Control
                  as="select"
                  required
                  className="billingForm"
                  value={formData.state}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {state.data.map((stateObj) => (
                    <option key={stateObj.abbreviation} value={stateObj.abbreviation}>
                      {stateObj.name}
                    </option>
                  ))}
                </Form.Control>
                {errors.state && <Form.Text className="text-danger">{errors.state}</Form.Text>}
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" style={{ width: "100%", background: "linear-gradient(270deg, rgb(51, 194, 223) 0%, rgb(0, 129, 197) 100%)", border: "none", marginTop: "20px", cursor: 'pointer' }} type="submit" disabled={loader}>
            {loader ? "Submitting..." : "Submit"}
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default BillingAddress;

