import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Form, Formik, RadioBlue } from "ui/forms";
import { EditIcon } from "assets/icons";
import Card from "ui/Card";
import { BasicButton } from "ui/Buttons";
import "react-datepicker/dist/react-datepicker.css";
import Table from "ui/table";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";
import { useHistory } from "react-router-dom";
import StepperComponent from "../Stepper";
import { UIContext } from "context/UIContext";
import ManageDefault from "./ManageDefault";
import { Route_URLs } from "routesURLs";
import Tags from "ui/Tags";
import { Authorise } from "helpers/Authorization";
import { DisputCustomers } from "queries/customers";
import useDebounce from "hooks/useDebounce";
import SelectWithVirtualized from "ui/SelectWithVirtualized";
import {
  ApprovedUsers,
  CustomerDisputAsignUserAdd,
  CustomerDisputTypeAsignUserAdd,
  FetchDisputeTypeData,
} from "mutations/adddiscount";
import CrossIcon from "assets/icon-cross.svg";
import axios from "utils/APIv2";
import { useRef } from "react";
import { useEffect } from "react";
import Loader from "ui/Loader";
import {
  BusinessDisputTypeAsignUserAdd,
  useAllbusiness,
} from "queries/business";
import { useProfile } from "queries/profile";

const StyledLabel = styled.span`
  font-family: Montserrat Alternates;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #909090;
  opacity: 1;
  margin-bottom: 8px;
  display: inline-block;
`;

const StyledCard = styled.div``;
const OuterDiv = styled.div`
  padding: 0px 20px;
  .HeadingText {
    letter-spacing: 0px;
    color: #909090;
    font-family: Montserrat Alternates-Bold;
    font-size: 18px;
    line-height: 22px;
    margin: 20px;
  }
`;
const TopSection = styled.div``;
const FilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledTable = styled.div`
  .table-wrapper {
    padding: 25px 0px;
  }
`;
const TopButtonsSection = styled.div`
  .black-background {
    background: #232631;
    border: 2px solid #909090;
  }
`;

const NavigationButtons = styled.div`
  .back-button {
    float: left;
    background: none;
    border: 2px solid #909090;
  }
  .next-button {
    float: right;
  }
  .skip-button {
    float: right;
    background: none;
    border: 2px solid #909090;
  }
  padding: 20px;
`;
const RadioButtonDiv = styled.div`
  .form-field {
    margin-top: 10px;
  }
  margin-top: 10px;
  border-bottom: 1px solid gray;
`;
const StyledSelect = styled.div`
  .react-select-container .react-select__control {
    background: transparent;
    box-shadow: 0px 3px 26px #00000017;
    border: 1px solid #ffffff;
    border-radius: 0px;
    color: #909091;
    height: 29px;
    padding: 1px;
    width: 100%;
  }
  .react-select-container .react-select__menu-list {
    background: #484a58 0% 0% no-repeat padding-box;
  }
  .react-select-container .react-select__option {
    color: #b5b5b5;
    border: 1px solid #ffffff;
    height: 13px;
    background: #3a3b48 0% 0% no-repeat padding-box;
    &:hover {
      background: #484a58 0% 0% no-repeat padding-box;
    }
  }
  .react-select-container .react-select__multi-value {
    background: #484a58 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 69px;
    height: 19px;
  }
  .react-select-container .react-select__multi-value__label {
    color: #b5b5b5;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 25px;
    align-items: center;
    border-radius: 8px;
    display: flex;
    padding-left: 8px;
    padding-right: 4px;
  }
  .react-select-container .react-select__input-container {
    color: #b5b5b5;
  }
  .react-select-container .react-select__multi-value__remove {
    background: #484a58 0% 0% no-repeat padding-box;
    &:hover {
      background: #484a58 0% 0% no-repeat padding-box;
      color: #b5b5b5;
    }
  }
  .react-select-container .react-select__indicators {
    display: none;
  }
  .react-select-container .react-select__placeholder {
    display: none;
  }
  .react-select-container .react-select__value-container {
    padding: 0px;
    position: absolute;
  }
`;
function DisputeManagement(props) {
  const { onBoarding } = useContext(UIContext);
  const [showDefault, setShowDefault] = useState(false);
  const [activeClass, setActiveClass] = useState(2);
  const hideModalDefault = () => setShowDefault(false);
  let history = useHistory();
  const [iseditableCustomerRowIndex, setEditableCustomerRowIndex] =
    useState(null);
  const [iseditableDisputRowIndex, setEditableDisputRowIndex] = useState(null);
  const [tags, setTags] = useState([]);
  const disputecatagory = FetchDisputeTypeData();
  const getApprovedUsers = ApprovedUsers();
  const { data: userData, isLoading } = useProfile();
  const userRole = userData?.role;

  const [page, setPage] = useState(0);
  const [initialPage, setinitialPage] = useState(0);

  const [filterData, setFilterData] = useState({
    customerName: "",
    company: "",
    location: "",
    sortOn: "customer",
    sortOrder: "asc",
  });
  const [customerLoader, setCustomerLoader] = useState(false);
  const makeid = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  useEffect(() => {
    setCustomerLoader(true);
    let value = makeid(3);
    let flterdata = { ...filterData, toreload: value };
    setFilterData(flterdata);

    setTimeout(() => setCustomerLoader(false), 600);
  }, []);

  const userAsignd = CustomerDisputAsignUserAdd();
  const userAsigndFordisputType = CustomerDisputTypeAsignUserAdd();

  const debouncedfilterData = useDebounce(filterData, 500);

  const { data: customers, isLoading: isLoadingCustomers } = DisputCustomers(
    page,
    debouncedfilterData
  );
  let customertableData =
    customers && customers?.customers ? customers.customers : [];

  const customerTotalRowsCount = customers ? customers.length : "";

  const handlePageChange = (_page) => {
    setPage(_page);
    setinitialPage(_page);
  };

  const updateData = (rowIndex, columnId, value) => {
    setTags((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const customerupdateData = (rowIndex, columnId, value) => {
    setTags((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const customerRowClick = (e, row, editId) => {
    return null;
  };

  const rowClick = (e, row, editId) => {
    return null;
  };
  const data = React.useMemo(
    () => [
      {
        business: "Business1",
        assigntoUsers: [
          { value: "User1", label: "User1" },
          { value: "User2", label: "User2" },
          { value: "User3", label: "User3" },
          { value: "User4", label: "User4" },
        ],
      },
      {
        business: "Business1",
        assigntoUsers: [
          { value: "User1", label: "User1" },
          { value: "User2", label: "User2" },
          { value: "User3", label: "User3" },
        ],
      },
      {
        business: "Business1",
        assigntoUsers: [
          { value: "User1", label: "User1" },
          { value: "User2", label: "User2" },
        ],
      },
      // {
      //   business: "Business1",
      //   assigntoUsers: "User 1, User 2, User 3, User 4",
      // },
      // {
      //   business: "Business1",
      //   assigntoUsers: "User 1, User 2, User 3, User 4",
      // },
      // {
      //   business: "Business1",
      //   assigntoUsers: "User 1, User 2, User 3, User 4",
      // },
      // {
      //   business: "Business1",
      //   assigntoUsers: "User 1, User 2, User 3, User 4",
      // },
    ],
    []
  );

  const customercolumns = React.useMemo(
    () => [
      {
        Header: "Customers",
        accessor: "customer",
        id: "customer",
      },

      {
        Header: "Assign to Users",
        accessor: "dispute_approvers",
        id: "AssigntoUsers",
        width: 425,
        minWidth: 250,
        Cell: (props) => {
          const onChange = async (e) => {
            let body = {
              customer_id: props.row.original.Id,
              approver_id: {
                user_id: e.value,
                user_name: e.label,
              },
            };

            userAsignd
              .mutateAsync({ body })
              .then((note) => {
                let value = makeid(3);
                let flterdata = { ...filterData, toreload: value };
                setFilterData(flterdata);
              })
              .catch(function (error) { });
          };

          const userDelete = (e) => {
            let body = {
              customer_id: props.row.original.Id,
              approver_id: {
                user_id: e.user_id,
                user_name: e.user_name,
              },
              deleteflag: 1,
            };

            userAsignd
              .mutateAsync({ body })
              .then((note) => {
                let value = makeid(3);
                let flterdata = { ...filterData, toreload: value };
                setFilterData(flterdata);
              })
              .catch(function (error) { });
            setFilterData({ ...filterData, dispute_approvers: "" });
          };

          return (
            <>
              {
                // iseditableCustomerUser ?
                props.row.index == iseditableCustomerRowIndex ? (
                  <StyledSelect>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {props?.row?.original?.dispute_approvers?.length > 0 && (
                        <div className="cheap_wrp">
                          {props?.row?.original?.dispute_approvers?.map(
                            (user) => (
                              <>
                                <div
                                  style={{
                                    background:
                                      "#484a58 0% 0% no-repeat padding-box",
                                    borderRadius: "10px",
                                    width: "fit-content",
                                    margin: "0 0 5px 5px",
                                    padding: "3px 8px",
                                  }}
                                  onClick={() => userDelete(user)}
                                  className="chipcls"
                                >
                                  {user.user_name}
                                  <button
                                    style={{
                                      background: "none",
                                      border: "none",
                                      color: "#fff",
                                      marginLeft: "5px",
                                    }}
                                    // onclick={userDelete}
                                    class="close"
                                    type="button"
                                  >
                                    x
                                  </button>
                                </div>
                              </>
                            )
                          )}
                        </div>
                      )}
                      <div className="right_input">
                        <div className="right_input_inner">
                          <SelectWithVirtualized
                            options={assigntoUsers.current}
                            onChange={(e) => onChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </StyledSelect>
                ) : (
                  <>
                    {props?.row?.original?.dispute_approvers?.map((user) => (
                      <>
                        {props?.row?.original?.dispute_approvers?.length > 1
                          ? `${user.user_name}, `
                          : user.user_name}
                      </>
                    ))}
                  </>
                )
              }
            </>
          );
        },
      },
      {
        id: "action",
        Header: "Action",
        // width: 200,
        disableSortBy: true,
        Cell: (props) => (
          <Authorise
            showUnAuthorisedComponenet={<></>}
            allowedRoles={["admin","simplicollect","SimpliCollect&Simplipay",]}
          >
            <EditIcon
              font-size="18px"
              onClick={(e) => {
                setEditableCustomerRowIndex(props.row.index);
                if (props.row.index == iseditableCustomerRowIndex) {
                  setEditableCustomerRowIndex(null);
                }
              }}
            />
          </Authorise>
        ),
      },
    ],
    [JSON.stringify(customertableData), iseditableCustomerRowIndex]
  );
  const [disputLoader, setDisputLoader] = useState(false);
  const [disputdata, setdisputdata] = useState([]);
  const disputBtnClick = async () => {
    setDisputLoader(true);
    setActiveClass(3);
    let response = await disputecatagory.mutateAsync({});
    if (response.data) {
      setdisputdata(response.data.data);
    }
    setDisputLoader(false);
  };

  let assigntoUsers = useRef([]);
  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    let response = await getApprovedUsers.mutateAsync({});

    if (response.data) {
      assigntoUsers.current = response.data.data;
    }
  };

  const disputcolumns = React.useMemo(
    () => [
      {
        Header: "Type of Disputs",
        accessor: "type",
        id: "type",
      },

      {
        Header: "Assign to Users",
        accessor: "users",
        id: "users",
        width: 425,
        minWidth: 250,
        Cell: (props) => {
          const onChange = (e) => {
            let body = {
              _id: props.row.original._id,
              users: {
                user_id: e.value,
                user_name: e.label,
              },
            };

            userAsigndFordisputType
              .mutateAsync({ body })
              .then((note) => {
                try {
                  disputecatagory
                    .mutateAsync({})
                    .then((response) => {
                      if (response.data) {
                        setdisputdata(response.data.data);
                      }
                    })
                    .catch(() => { });
                } catch (error) { }
              })
              .catch(function (error) {
                console.log(error);
              });
          };

          const userDelete = (e) => {
            let body = {
              _id: props.row.original._id,
              users: {
                user_id: e.user_id,
                user_name: e.user_name,
              },
              deleteflag: 1,
            };

            userAsigndFordisputType
              .mutateAsync({ body })
              .then((note) => {
                try {
                  disputecatagory
                    .mutateAsync({})
                    .then((response) => {
                      if (response.data) {
                        setdisputdata(response.data.data);
                      }
                    })
                    .catch(() => { });
                } catch (error) { }
              })
              .catch(function (error) { });
          };

          return (
            <>
              {props.row.index == iseditableDisputRowIndex ? (
                <StyledSelect>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {props?.row?.original?.users?.length > 0 && (
                      <div className="cheap_wrp">
                        {props?.row?.original?.users?.map((user) => (
                          <>
                            <div
                              style={{
                                background:
                                  "#484a58 0% 0% no-repeat padding-box",
                                borderRadius: "10px",
                                width: "fit-content",
                                margin: "0 0 5px 5px",
                                padding: "3px 8px",
                              }}
                              onClick={() => userDelete(user)}
                              className="chipcls"
                            >
                              {user.user_name}
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  color: "#fff",
                                  marginLeft: "5px",
                                }}
                                class="close"
                                type="button"
                              >
                                x
                              </button>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                    <div className="right_input">
                      <div className="right_input_inner">
                        <SelectWithVirtualized
                          options={assigntoUsers.current}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </StyledSelect>
              ) : (
                <>
                  {props?.row?.original?.users?.map((user) => (
                    <>
                      {props?.row?.original?.users?.length > 1
                        ? `${user.user_name}, `
                        : user.user_name}
                    </>
                  ))}
                </>
              )}
            </>
          );
        },
      },
      {
        id: "action",
        Header: "Action",
        // width: 300,
        disableSortBy: true,
        Cell: (props) => (
          <Authorise
            showUnAuthorisedComponenet={<></>}
            allowedRoles={["admin","simplicollect","SimpliCollect&Simplipay",]}
          >
            <EditIcon
              font-size="18px"
              onClick={(e) => {
                setEditableDisputRowIndex(props.row.index);
                if (props.row.index == iseditableDisputRowIndex) {
                  setEditableDisputRowIndex(null);
                }
              }}
            />
          </Authorise>
        ),
      },
    ],
    [JSON.stringify(disputdata), iseditableDisputRowIndex]
  );

  {
    /********************************Business section***************************** */
  }

  const [filterDataBusiness, setFilterDataBusiness] = useState({
    name: "",
    description: "",
    parent_business: "",
    pageNumber: 0,
    limit: "",
    order: "desc",
    columnName: "name",
  });
  const [initialPageBusiness, setinitialPageBusiness] = useState(0);

  const debouncedfilterDataBusiness = useDebounce(filterDataBusiness, 1000);

  const { data: businessdata, isLoading: isloadingBusiness } = useAllbusiness(
    debouncedfilterDataBusiness
  );
  let businessListdata = businessdata ? businessdata.data : [];
  const totalcount = businessdata ? businessdata.count : "";

  const userAsigndFrBusiness = BusinessDisputTypeAsignUserAdd();

  const handlePageChangeBusiness = (page) => {
    filterDataBusiness.pageNumber = page;

    setFilterDataBusiness({ ...filterDataBusiness, ...filterDataBusiness });
    setinitialPageBusiness(page);
  };

  const rowClickBusiness = (e, row, editId) => {
    return null;
  };

  const [iseditableBusinessRowIndex, setIseditableBusinessRowIndex] =
    useState(null);

  const onHeaderClick = (column) => {
    filterData.sortOn = column.id;
    filterData.sortOrder = filterData.sortOrder === "desc" ? "asc" : "desc";
    setFilterData({ ...filterData, ...filterData });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Business",
        accessor: "name",
        id: "name",
      },

      {
        Header: "Assign to Users",
        accessor: "disput_approvers",
        id: "disput_approvers",
        width: 315,
        minWidth: 250,
        Cell: (props) => {
          const onChange = (e) => {
            let body = {
              _id: props.row.original._id,
              approver_id: {
                user_id: e.value,
                user_name: e.label,
              },
            };

            userAsigndFrBusiness
              .mutateAsync({ body })
              .then((note) => {
                let value = makeid(3);
                let flterdata = { ...filterDataBusiness, toreload: value };
                setFilterDataBusiness(flterdata);
              })
              .catch(function (error) { });
          };

          const userDelete = (e) => {
            let body = {
              _id: props.row.original._id,
              approver_id: {
                user_id: e.user_id,
                user_name: e.user_name,
              },
              deleteflag: 1,
            };

            userAsigndFrBusiness
              .mutateAsync({ body })
              .then((note) => {
                let value = makeid(3);
                let flterdata = { ...filterDataBusiness, toreload: value };
                setFilterDataBusiness(flterdata);
              })
              .catch(function (error) { });
          };

          return (
            <>
              {props.row.index == iseditableBusinessRowIndex ? (
                <StyledSelect>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {props?.row?.original?.disput_approvers?.length > 0 && (
                      <div className="cheap_wrp">
                        {props?.row?.original?.disput_approvers?.map(
                          (approverdata) => (
                            <>
                              <div
                                style={{
                                  background:
                                    "#484a58 0% 0% no-repeat padding-box",
                                  borderRadius: "10px",
                                  width: "fit-content",
                                  margin: "0 0 5px 5px",
                                  padding: "3px 8px",
                                }}
                                onClick={() => userDelete(approverdata)}
                                className="chipcls"
                              >
                                {approverdata.user_name}
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                    color: "#fff",
                                    marginLeft: "5px",
                                  }}
                                  class="close"
                                  type="button"
                                >
                                  x
                                </button>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    )}
                    <div className="right_input">
                      <div className="right_input_inner">
                        <SelectWithVirtualized
                          options={assigntoUsers.current}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </StyledSelect>
              ) : (
                <>
                  {props?.row?.original?.disput_approvers?.map(
                    (approverdata) => (
                      <>
                        {props?.row?.original?.disput_approvers?.length > 1
                          ? `${approverdata.user_name}, `
                          : approverdata.user_name}
                      </>
                    )
                  )}
                </>
              )}
            </>
          );
        },
      },
      {
        id: "action",
        Header: "Action",
        width: 300,
        disableSortBy: true,
        Cell: (props) => (
          <Authorise
            showUnAuthorisedComponenet={<></>}
            allowedRoles={["admin","simplicollect","SimpliCollect&Simplipay",]}
          >
            <EditIcon
              font-size="18px"
              onClick={(e) => {
                setIseditableBusinessRowIndex(props.row.index);
                if (props.row.index == iseditableBusinessRowIndex) {
                  setIseditableBusinessRowIndex(null);
                }
              }}
            />
          </Authorise>
        ),
      },
    ],
    [JSON.stringify(businessListdata), iseditableBusinessRowIndex]
  );

  return (
    <>
      <PrivateLayout>
        <Page>
          <Page.Header>
            <Row className="m-0">
              <Col className="p-0">
                <h1 className="page-title">Dispute Management</h1>
                <StepperComponent currentPage={onBoarding ? 5 : 8} />
              </Col>
            </Row>
          </Page.Header>
          <Page.Body>
            <StyledCard>
              <Card>
                <Card.Body>
                  <OuterDiv>
                    <Formik
                      initialValues={{}}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(true);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <>
                          <Form onSubmit={handleSubmit} className="mx-auto">
                            <TopSection>
                              <RadioButtonDiv>
                                <Row>
                                  <Col sm={9}>
                                    <RadioBlue
                                      name="access"
                                      options={[
                                        {
                                          key: "Allow specific members along with the default team.",
                                          value: "1",
                                          id: "1",
                                        },
                                        {
                                          key: "Allow only specific members (when configured) to manage disputes.",
                                          value: "1",
                                          id: "2",
                                        },
                                      ]}
                                    ></RadioBlue>
                                  </Col>
                                  <Col sm={3}>
                                    <Authorise
                                      showUnAuthorisedComponenet={<></>}
                                      allowedRoles={["admin","SimpliCollect&Simplipay","simplicollect"]}
                                    >
                                      <FilterSection>
                                        <BasicButton
                                          type="button"
                                          width="150px"
                                          onClick={() => setShowDefault(true)}
                                        >
                                          Manage defaults
                                        </BasicButton>
                                      </FilterSection>
                                    </Authorise>
                                  </Col>
                                </Row>
                              </RadioButtonDiv>
                              <Row>
                                <Col sm={12}>
                                  <p className="HeadingText">
                                    In this section, tell us how you want
                                    Customer Disputes to be assigned and to
                                    whom?
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <TopButtonsSection>
                                    {/* <BasicButton
                                      type="button"
                                      width="200px"
                                      className={
                                        activeClass === 1
                                          ? ""
                                          : "black-background"
                                      }
                                      onClick={() => setActiveClass(1)}
                                    >
                                      Business Hierarchy
                                    </BasicButton> */}
                                    <BasicButton
                                      // className="black-background"
                                      className={
                                        activeClass === 2
                                          ? ""
                                          : "black-background"
                                      }
                                      type="button"
                                      width="110px"
                                      onClick={() => setActiveClass(2)}
                                    >
                                      Customer
                                    </BasicButton>
                                    <BasicButton
                                      className={
                                        activeClass === 3
                                          ? ""
                                          : "black-background"
                                      }
                                      type="button"
                                      width="120px"
                                      // onClick={() => setActiveClass(3)}
                                      onClick={() => disputBtnClick()}
                                    >
                                      Dispute Type
                                    </BasicButton>
                                  </TopButtonsSection>
                                </Col>
                              </Row>
                            </TopSection>
                            <Row>
                              <div className="disputecol">
                                <Col>
                                  <StyledTable>
                                    {activeClass === 1 && (
                                      <>
                                        <Table
                                          data={businessListdata}
                                          columns={columns}
                                          updateData={updateData}
                                          rowClick={rowClickBusiness}
                                          handlePageChange={
                                            handlePageChangeBusiness
                                          }
                                          totalCount={totalcount}
                                          initialPage={initialPageBusiness}
                                        />
                                      </>
                                    )}
                                    {activeClass === 2 && (
                                      <Table
                                        data={customertableData}
                                        columns={customercolumns}
                                        updateData={customerupdateData}
                                        rowClick={customerRowClick}
                                        handlePageChange={handlePageChange}
                                        totalCount={customerTotalRowsCount}
                                        initialPage={initialPage}
                                        isloading={isLoadingCustomers}
                                        onHeaderClick={onHeaderClick}
                                      />
                                    )}

                                    {activeClass === 3 && (
                                      <Table
                                        data={disputdata}
                                        columns={disputcolumns}
                                        updateData={updateData}
                                        rowClick={rowClick}
                                      />
                                    )}
                                    {disputLoader === true &&
                                      disputdata.length === 0 && <Loader />}
                                  </StyledTable>
                                </Col>
                              </div>
                            </Row>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </OuterDiv>
                </Card.Body>
              </Card>
            </StyledCard>
            <NavigationButtons>
              <BasicButton
                className="back-button"
                type="button"
                width="110px"
                onClick={() =>
                  history.push(Route_URLs.setup_customer_notification)
                }
              >
                Back
              </BasicButton>


              {userRole == 'simplicollect' ? <BasicButton
                className="next-button"
                type="button"
                width="180px"
                onClick={(e) => {
                  onBoarding
                    ? history.push(Route_URLs.profile)
                    : history.push(Route_URLs.dashboard);
                }}
              >
                {onBoarding ? "Go to Profile" : "Go to Dashboard"}
              </BasicButton> : <><BasicButton
                className="next-button"
                type="button"
                width="110px"
                onClick={() => history.push(Route_URLs.setup_supplier_payment)}
              >
                Next
              </BasicButton>
    
              </>
              }


            </NavigationButtons>
          </Page.Body>
        </Page>
      </PrivateLayout>
      <ManageDefault show={showDefault} onHide={hideModalDefault} />
    </>
  );
}
export default DisputeManagement;
