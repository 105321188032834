import BaseService from "./baseClass.services";
import moment from "moment";
import { apiURL, awsExportUrl } from "utils/base-url";
import axios from "utils/APIv2";
import FileSaver from "file-saver";

class ActualService extends BaseService {
  getallreceviables = async (filterData, mo = false) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";
    const params = {
      field: filterData.columnName,
      sort: filterData.order,
      pageNo: filterData.pageNumber,
      limit: 10,
      customerName: filterData.customerName ? filterData.customerName : "",
      minAmount: filterData.minAmount,
      maxAmount: filterData.maxAmount,
      startInvoiceDate: formatedInvoiceStart,
      endInvoiceDate: formatedInvoiceEnd,
      startDueDate: formatedDeliveryStart,
      endDueDate: formatedDeliveryEnd,
      bucketFilter: filterData.bucket,
      topCustomerLimit: filterData.limit,
      forecast: filterData.forecast,
      invoiceNumber: filterData.invoiceNumber,
      mo: mo,
      receivableType: filterData.receivableType,
      salesRepName: filterData.salesRepName ? filterData.salesRepName : "",
      jobType: filterData.jobType ? filterData.jobType : "",
    };
    const res = await axios.get(`/receivables/actual/listing`, { params });
    return res.data;
  };

  getallcollections = async (filterData) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedCollectedStart =
      filterData?.deliveryCollectedDate && filterData?.deliveryCollectedDate[0]
        ? moment(filterData?.deliveryCollectedDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedCollectedEnd =
      filterData?.deliveryCollectedDate && filterData?.deliveryCollectedDate[1]
        ? moment(filterData?.deliveryCollectedDate[1]).format("YYYY-MM-DD")
        : "";
    const params = {
      field: filterData.columnName,
      sort: filterData.order,
      pageNo: filterData.pageNumber,
      limit: 10,
      customerName: filterData.searchString,
      minAmount: filterData.minAmount,
      maxAmount: filterData.maxAmount,
      startInvoiceDate: formatedInvoiceStart,
      endInvoiceDate: formatedInvoiceEnd,
      startDueDate: formatedDeliveryStart,
      endDueDate: formatedDeliveryEnd,
      bucketFilter: filterData.bucket,
      topCustomerLimit: filterData.limit,
      startCollectionDate: formatedCollectedStart,
      endCollectionDate: formatedCollectedEnd,
      salesRepName: filterData.salesRepName,
      jobType: filterData.jobType,
      partialPaid: filterData.partialPaid,
    };
    const res = await this.axios.get(`/collections/actual/listing`, { params });
    return res.data;
  };

  getallpayables = async (filterData, mo = false) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";
    const params = {
      field: filterData.columnName,
      sort: filterData.order,
      pageNo: filterData.pageNumber,
      limit: 10,
      supplierName: filterData.searchString,
      minAmount: filterData.minAmount,
      maxAmount: filterData.maxAmount,
      startInvoiceDate: formatedInvoiceStart,
      endInvoiceDate: formatedInvoiceEnd,
      startDueDate: formatedDeliveryStart,
      endDueDate: formatedDeliveryEnd,
      bucketFilter: filterData.bucket,
      topSupplierLimit: filterData.limit,
      mo: mo,
      payableType: filterData.payableType,
    };
    const res = await axios.get(`/payables/actual/listing`, { params });
    return res.data;
  };

  getNonControllablePayableData = async (filterData) => {
    const res = await axios.get(
      `/cashClassification/nonTradeAndNonControllablesData/nonControllablePayables`,
      { params: filterData }
    );
    return res.data;
  };

  getNonTradeRecievavleData = async (filterData) => {
    const res = await axios.get(
      `/cashClassification/nonTradeAndNonControllablesData/nonTradeRecievables`,
      { params: filterData }
    );
    return res.data;
  };

  getgraphdatareceivables = async (cid, filterType) => {
    const params = {
      customerId: cid,
      filterType,
    };
    const res = await axios.get(`/receivables/actual/graph`, { params });
    return res.data.data;
  };

  getgraphdatacollections = async (cid, filterType) => {
    const res = await axios.get(`/collections/actual/graph`, {
      params: { customerId: cid, filterType },
    });
    return res.data.data;
  };

  getgraphdatapayables = async (sid, filterType) => {
    const res = await axios.get(`/payables/actual/graph`, {
      params: { supplierId: sid, filterType },
    });
    return res.data.data;
  };

  getcarddatareceivables = async (filterData, mo = false) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";
    const params = {
      customerName: filterData.customerName ? filterData.customerName : "",
      minAmount: filterData.minAmount,
      maxAmount: filterData.maxAmount,
      startInvoiceDate: formatedInvoiceStart,
      endInvoiceDate: formatedInvoiceEnd,
      startDueDate: formatedDeliveryStart,
      endDueDate: formatedDeliveryEnd,
      bucketFilter: filterData.bucket,
      forecast: filterData.forecast,
      invoiceNumber: filterData.invoiceNumber,
      mo: mo,
      receivableType: filterData.receivableType,
      salesRepName: filterData.salesRepName ? filterData.salesRepName : "",
      jobType: filterData.jobType ? filterData.jobType : "",
      topCustomerLimit: filterData.limit,
    };
    const res = await axios.get(`/receivables/actual/total`, { params });
    return res.data;
  };
  getLogCustomerData = async (userId) => {
    const res = await axios.get(`/customers/getcustomer-details`, {
      params: userId,
    });
    return res.data;
  };
  getcustomercarddatapayables = async (decodedData) => {
    const res = await axios.get(`/customers/invoicelist/total`, {
      params: { userId: decodedData.username },
    });
    return res.data;
  };
  getallcustomers = async (filterData, mo = false) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";
    const params = {
      field: filterData.field,
      sort: filterData.order,
      pageNo: filterData.pageNumber,
      limit: 10,
      customerName: filterData.customerName
        ? filterData.customerName.value
        : "",
      minAmount: filterData.minAmount,
      maxAmount: filterData.maxAmount,
      startInvoiceDate: formatedInvoiceStart,
      endInvoiceDate: formatedInvoiceEnd,
      startDueDate: formatedDeliveryStart,
      endDueDate: formatedDeliveryEnd,
      bucketFilter: filterData.bucket,
      topCustomerLimit: filterData.limit,
      forecast: filterData.forecast,
      invoiceNumber: filterData.invoiceNumber,
      txn_type: filterData.txn_type?.value,
      mo: mo,
      card: filterData.card,
      customerId: filterData.customerId,
      disputstatus: filterData.disputstatus,
    };
    const res = await axios.get(`/customers/invoicelist`, { params });
    return res.data;
  };

  getallcustomersCsv = async (filterData) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";

    let sessionStoragetoken = sessionStorage.getItem("userToken");

    // return `${apiURL}/customers/invoicelist/export?field=${
    //   filterData.columnName
    // }&sort=${filterData.order}&pageNo=${
    //   filterData.pageNumber
    // }&limit=10&customerName=${filterData.customerName}&minAmount=${
    //   filterData.minAmount
    // }&maxAmount=${
    //   filterData.maxAmount
    // }&startInvoiceDate=${formatedInvoiceStart}&endInvoiceDate=${formatedInvoiceEnd}&startDueDate=${formatedDeliveryStart}&endDueDate=${formatedDeliveryEnd}&bucketFilter=${
    //   filterData.bucket
    // }&topCustomerLimit=${filterData.limit}&forecast=${
    //   filterData.forecast
    // }&invoiceNumber=${filterData.invoiceNumber}&disputstatus=${
    //   filterData.disputstatus
    // }&card=${filterData.card}&key=${sessionStorage.getItem("userToken")}`;
    let res = await axios.get(
      `${apiURL}/customers/invoicelist/lambda/export?field=${
        filterData.columnName
      }&sort=${filterData.order}&pageNo=${
        filterData.pageNumber
      }&limit=10&customerName=${filterData.customerName}&minAmount=${
        filterData.minAmount
      }&maxAmount=${
        filterData.maxAmount
      }&startInvoiceDate=${formatedInvoiceStart}&endInvoiceDate=${formatedInvoiceEnd}&startDueDate=${formatedDeliveryStart}&endDueDate=${formatedDeliveryEnd}&bucketFilter=${
        filterData.bucket
      }&topCustomerLimit=${filterData.limit}&forecast=${
        filterData.forecast
      }&invoiceNumber=${filterData.invoiceNumber}&disputstatus=${
        filterData.disputstatus
      }&card=${filterData.card}&key=${sessionStorage.getItem("userToken")}`
    );

    return res.data.downloadUrl;
  };

  getcarddatacollections = async (filterData, mo = false) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedCollectedStart =
      filterData?.deliveryCollectedDate && filterData?.deliveryCollectedDate[0]
        ? moment(filterData?.deliveryCollectedDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedCollectedEnd =
      filterData?.deliveryCollectedDate && filterData?.deliveryCollectedDate[1]
        ? moment(filterData?.deliveryCollectedDate[1]).format("YYYY-MM-DD")
        : "";
    const params = {
      customerName: filterData.customerName ? filterData.customerName : "",
      minAmount: filterData.minAmount,
      maxAmount: filterData.maxAmount,
      startInvoiceDate: formatedInvoiceStart,
      endInvoiceDate: formatedInvoiceEnd,
      startDueDate: formatedDeliveryStart,
      endDueDate: formatedDeliveryEnd,
      bucketFilter: filterData.bucket,
      invoiceNumber: filterData.invoiceNumber,
      mo: mo,
      startCollectionDate: formatedCollectedStart,
      endCollectionDate: formatedCollectedEnd,
      topCustomerLimit: filterData.limit,
      salesRepName: filterData.salesRepName,
      jobType: filterData.jobType,
      partialPaid: filterData.partialPaid,
    };
    const res = await axios.get(`/collections/actual/total`, { params });
    return res.data;
  };

  getcarddatapayables = async (filterData, mo = false) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";
    const params = {
      supplierName: filterData.supplierName ? filterData.supplierName : "",
      minAmount: filterData.minAmount,
      maxAmount: filterData.maxAmount,
      startInvoiceDate: formatedInvoiceStart,
      endInvoiceDate: formatedInvoiceEnd,
      startDueDate: formatedDeliveryStart,
      endDueDate: formatedDeliveryEnd,
      bucketFilter: filterData.bucket,
      forecast: filterData.forecast,
      invoiceNumber: filterData.invoiceNumber,
      mo: mo,
      payableType: filterData.payableType,
      topSupplierLimit: filterData.limit,
    };
    const res = await axios.get(`/payables/actual/total`, { params });
    return res.data;
  };

  getCardDataControllableNonControllable = async () => {
    const res = await axios.get(
      `/cashClassification/nonTradeAndNonControllablesTotal`
    );
    return res.data;
  };

  updateReceivableData = (id, payload) => {
    return this.axios.patch(`/receivables/actual/${id}`, payload);
  };

  updatePayableData = (id, payload) => {
    return this.axios.patch(`/payables/actual/${id}`, payload);
  };

  //export urls
  exportUrlReceivable = async (filterData) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";
    const customerName = filterData.customerName ? filterData.customerName : "";

    const receivableType = filterData.receivableType;

    const salesRepName = filterData.salesRepName ? filterData.salesRepName : "";

    const jobType = filterData.jobType ? filterData.jobType : "";

    const tenant = sessionStorage.getItem("tenantName");
    const res = await axios.get(
      `${apiURL}/receivables/lambda/export?field=${
        filterData.columnName
      }&sort=${filterData.order}&pageNo=${
        filterData.pageNumber
      }&limit=10&customerName=${customerName}&minAmount=${
        filterData.minAmount
      }&maxAmount=${
        filterData.maxAmount
      }&startInvoiceDate=${formatedInvoiceStart}&endInvoiceDate=${formatedInvoiceEnd}&startDueDate=${formatedDeliveryStart}&endDueDate=${formatedDeliveryEnd}&bucketFilter=${
        filterData.bucket
      }&topCustomerLimit=${filterData.limit}&forecast=${
        filterData.forecast
      }&invoiceNumber=${
        filterData.invoiceNumber
      }&receivableType=${receivableType}&tenant=${tenant}&salesRepName=${salesRepName}&jobType=${jobType}&file=receivables&key=${sessionStorage.getItem(
        "userToken"
      )}`
    );
    return res.data.downloadUrl;
  };

  exportUrlPayable = async (filterData) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";
    const payableType =
      filterData?.payableType && filterData?.payableType !== ""
        ? filterData.payableType
        : "";

    const tenant = sessionStorage.getItem("tenantName");
    const res = await axios.get(
      `${apiURL}/payables/lambda/export?field=${filterData.columnName}&sort=${
        filterData.order
      }&pageNo=${filterData.pageNumber}&limit=10&supplierName=${
        filterData.searchString
      }&minAmount=${filterData.minAmount}&maxAmount=${
        filterData.maxAmount
      }&startInvoiceDate=${formatedInvoiceStart}&endInvoiceDate=${formatedInvoiceEnd}&startDueDate=${formatedDeliveryStart}&endDueDate=${formatedDeliveryEnd}&bucketFilter=${
        filterData.bucket
      }&topSupplierLimit=${
        filterData.limit
      }&payableType=${payableType}&tenant=${tenant}&file=payables&key=${sessionStorage.getItem(
        "userToken"
      )}`
    );
    return res.data.downloadUrl;
  };

  exportUrlNonTradeControllables = async (filterData, type) => {
    const tenant = sessionStorage.getItem("tenantName");
    const res = await axios.get(
      `${apiURL}/cashClassification/generalledger/lambda/export?account=${
        filterData.account || ""
      }&business=${filterData.business || ""}&greaterValue=${
        filterData.greaterValue || ""
      }&lessValue=${filterData.lessValue || ""}&accountId=${
        filterData.accountId || ""
      }&key=${sessionStorage.getItem(
        "userToken"
      )}&tenant=${tenant}&file=generalledger&type=${type}&isExport=true`
    );
    return res.data.downloadUrl;
  };

  // exportUrlNonTradeControllables = async (filterData, type) => {
  //   const params = JSON.parse(JSON.stringify(filterData));
  //   params.responseType = 'blob';
  //   params['isExport'] = true;
  //   const res = await this.axios.get(`/cashClassification/nonTradeAndNonControllablesData/${type}`, { params })

  //   let buffer = Buffer.from(res?.data);
  //   // const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  //   const blob = new Blob([buffer], { type: "application/vnd.ms-excel;charset=utf-8" });
  //   setTimeout(function () {
  //     FileSaver.saveAs(blob, `${type}Data.xlsx`)
  //   }, 1000);
  //   // return res.data;
  //   // return `${apiURL}/cashClassification/nonTradeAndNonControllablesData/${type}/export?account=${filterData.account || ''
  //   //   }&business=${filterData.business || ''
  //   //   }&greaterValue=${filterData.greaterValue || ''
  //   //   }&lessValue=${filterData.lessValue || ''
  //   //   }&key=${sessionStorage.getItem('userToken')
  //   //   }&isExport=true`;
  // };

  exportUrlCollection = async (filterData) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";
    const formateddeliveryCollectedEndDate =
      filterData?.deliveryCollectedDate && filterData?.deliveryCollectedDate[1]
        ? moment(filterData?.deliveryCollectedDate[1]).format("YYYY-MM-DD")
        : "";
    const formateddeliveryCollectedstartDate =
      filterData?.deliveryCollectedDate && filterData?.deliveryCollectedDate[0]
        ? moment(filterData?.deliveryCollectedDate[0]).format("YYYY-MM-DD")
        : "";
    //  return `${apiURL}/collections/export?field=${filterData.columnName}&sort=${filterData.order
    //     }&pageNo=${filterData.pageNumber}&limit=10&customerName=${filterData.searchString
    //     }&minAmount=${filterData.minAmount}&maxAmount=${filterData.maxAmount
    //     }&startInvoiceDate=${formatedInvoiceStart}&endInvoiceDate=${formatedInvoiceEnd}&startDueDate=${formatedDeliveryStart}&endDueDate=${formatedDeliveryEnd}&startCollectionDate=${formateddeliveryCollectedstartDate}&endCollectionDate=${formateddeliveryCollectedEndDate}&bucketFilter=${filterData.bucket
    //     }&topCustomerLimit=${filterData.limit}&salesRepName=${filterData.salesRepName}&jobType=${filterData.jobType}&key=${sessionStorage.getItem(
    //       "userToken"
    //     )}`;
    const tenant = sessionStorage.getItem("tenantName");
    const res = await axios.get(
      `${apiURL}/collections/lambda/export?field=${
        filterData.columnName
      }&sort=${filterData.order}&pageNo=${
        filterData.pageNumber
      }&limit=10&customerName=${filterData.searchString}&minAmount=${
        filterData.minAmount
      }&maxAmount=${
        filterData.maxAmount
      }&startInvoiceDate=${formatedInvoiceStart}&endInvoiceDate=${formatedInvoiceEnd}&startDueDate=${formatedDeliveryStart}&endDueDate=${formatedDeliveryEnd}&startCollectionDate=${formateddeliveryCollectedstartDate}&endCollectionDate=${formateddeliveryCollectedEndDate}&bucketFilter=${
        filterData.bucket
      }&topCustomerLimit=${filterData.limit}&salesRepName=${
        filterData.salesRepName
      }&jobType=${
        filterData.jobType
      }&tenant=${tenant}&file=collections&key=${sessionStorage.getItem(
        "userToken"
      )}`
    );
    return res.data.downloadUrl;
    //return `${awsExportUrl}?field=${filterData.columnName}&sort=${filterData.order}&pageNo=${filterData.pageNumber}&limit=10&customerName=${filterData.searchString}&minAmount=${filterData.minAmount}&maxAmount=${filterData.maxAmount}&startInvoiceDate=${formatedInvoiceStart}&endInvoiceDate=${formatedInvoiceEnd}&startDueDate=${formatedDeliveryStart}&endDueDate=${formatedDeliveryEnd}&startCollectionDate=${formateddeliveryCollectedstartDate}&endCollectionDate=${formateddeliveryCollectedEndDate}&bucketFilter=${filterData.bucket}&topCustomerLimit=${filterData.limit}&salesRepName=${filterData.salesRepName}&jobType=${filterData.jobType}`;
  };

  exportUrlBarGraphReceivables = (customerid, timeFilter) => {
    return `${apiURL}/receivables/graph/export?customerId=${customerid}&filterType=${timeFilter}&key=${sessionStorage.getItem(
      "userToken"
    )}`;
  };

  exportUrlBarGraphPayables = (supplierid, timeFilter) => {
    return `${apiURL}/payables/graph/export?supplierId=${supplierid}&filterType=${timeFilter}&key=${sessionStorage.getItem(
      "userToken"
    )}`;
  };

  exportUrlBarGraphCollections = (customerid, timeFilter) => {
    return `${apiURL}/collections/graph/export?customerId=${customerid}&filterType=${timeFilter}&key=${sessionStorage.getItem(
      "userToken"
    )}`;
  };
  getreminders = (filterData) => {
    return this.axios.post(`/customers/getCustomerEmailReminder`, filterData);
  };
  getAalldisputeType = (userId) => {
    return this.axios.get(`/customers/fetch-alldispute-type`, {
      params: userId,
    });
  };
  billDownload = (_id) => {
    return `${apiURL}/payment_optimization/export?_id=${_id}&key=${sessionStorage.getItem(
      "userToken"
    )}`;
  };

  getallpayments = async (filterData, mo = false) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";

    const formatedPaymentStart =
      filterData?.deliveryPaymentDate && filterData?.deliveryPaymentDate[0]
        ? moment(filterData?.deliveryPaymentDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedPaymentEnd =
      filterData?.deliveryPaymentDate && filterData?.deliveryPaymentDate[1]
        ? moment(filterData?.deliveryPaymentDate[1]).format("YYYY-MM-DD")
        : "";

    const params = {
      field: filterData.columnName,
      sort: filterData.order,
      pageNo: filterData.pageNumber,
      limit: 10,
      supplierName: filterData.searchString,
      minAmount: filterData.greaterValue,
      maxAmount: filterData.lessValue,
      startInvoiceDate: formatedInvoiceStart,
      endInvoiceDate: formatedInvoiceEnd,
      startDueDate: formatedDeliveryStart,
      endDueDate: formatedDeliveryEnd,

      startPaymentDate: formatedPaymentStart,
      endPaymentDate: formatedPaymentEnd,
      bucketFilter: filterData.bucket,
      topSupplierLimit: filterData.limit,
      invoiceId: filterData.transaction,
      mo: mo,
      transactionType: filterData.transactionType?.value
        ? filterData?.transactionType?.value
        : "",
    };
    const res = await axios.get(`/actual-payments-list`, { params });
    return res.data;
  };

  getcarddatapayments = async (filterData) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";

    const formatedPaymentStart =
      filterData?.deliveryPaymentDate && filterData?.deliveryPaymentDate[0]
        ? moment(filterData?.deliveryPaymentDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedPaymentEnd =
      filterData?.deliveryPaymentDate && filterData?.deliveryPaymentDate[1]
        ? moment(filterData?.deliveryPaymentDate[1]).format("YYYY-MM-DD")
        : "";

    const params = {
      minAmount: filterData.greaterValue,
      maxAmount: filterData.lessValue,
      startInvoiceDate: formatedInvoiceStart,
      endInvoiceDate: formatedInvoiceEnd,
      startDueDate: formatedDeliveryStart,
      endDueDate: formatedDeliveryEnd,

      startPaymentDate: formatedPaymentStart,
      endPaymentDate: formatedPaymentEnd,
      bucketFilter: filterData.bucket,
      invoiceId: filterData.transaction,
      supplierName: filterData.searchString,
      topSupplierLimit: filterData.limit,
      transactionType: filterData.transactionType?.value,

      // mo: mo,
    };

    const res = await axios.get(`/payments/actual/total`, { params });
    return res.data;
  };

  getDownloadTransaction = async (filterData) => {
    let startDate = "";
    let endDate = "";
    if (filterData.startDate) {
      startDate = moment(filterData.startDate).format("YYYY-MM-DD");
    }
    if (filterData.endDate) {
      endDate = moment(filterData.endDate).format("YYYY-MM-DD");
    }
    const tenant = sessionStorage.getItem("tenantName");

    let res = await axios.get(
      `${apiURL}/transaction-report/lambda/export?startDate=${startDate}&endDate=${endDate}&tenant=${tenant}&key=${sessionStorage.getItem(
        "userToken"
      )}`
    );
    return res.data.downloadUrl;
    // return `${apiURL}/transaction-report/export?startDate=${startDate}&endDate=${endDate}&key=${sessionStorage.getItem(
    //   "userToken"
    // )}`
  };

  exportUrlPayments = async (filterData) => {
    const formatedInvoiceStart =
      filterData?.invoiceDate && filterData?.invoiceDate[0]
        ? moment(filterData?.invoiceDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedInvoiceEnd =
      filterData?.invoiceDate && filterData?.invoiceDate[1]
        ? moment(filterData?.invoiceDate[1]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryStart =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[0]
        ? moment(filterData?.deliveryDueDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedDeliveryEnd =
      filterData?.deliveryDueDate && filterData?.deliveryDueDate[1]
        ? moment(filterData?.deliveryDueDate[1]).format("YYYY-MM-DD")
        : "";

    const formatedPaymentStart =
      filterData?.deliveryPaymentDate && filterData?.deliveryPaymentDate[0]
        ? moment(filterData?.deliveryPaymentDate[0]).format("YYYY-MM-DD")
        : "";
    const formatedPaymentEnd =
      filterData?.deliveryPaymentDate && filterData?.deliveryPaymentDate[1]
        ? moment(filterData?.deliveryPaymentDate[1]).format("YYYY-MM-DD")
        : "";

    const tenant = sessionStorage.getItem("tenantName");
    const res = await axios.get(
      `${apiURL}/payments/lambda/export?field=${filterData.columnName}&sort=${
        filterData.order
      }&pageNo=${filterData.pageNumber}&limit=10&supplierName=${
        filterData.searchString
      }&minAmount=${filterData.greaterValue}&maxAmount=${
        filterData.lessValue
      }&startInvoiceDate=${formatedInvoiceStart}&endInvoiceDate=${formatedInvoiceEnd}&startDueDate=${formatedDeliveryStart}&invoiceId=${
        filterData.transaction
      }
      &endDueDate=${formatedDeliveryEnd}&startPaymentDate=${formatedPaymentStart}&endPaymentDate=${formatedPaymentEnd}
      &bucketFilter=${filterData.bucket}&topSupplierLimit=${
        filterData.limit
      }&transactionType=${
        filterData.transactionType?.value != undefined &&
        filterData.transactionType?.value != null &&
        filterData.transactionType != ""
          ? filterData.transactionType?.value
          : ""
      }&tenant=${tenant}&file=payments&key=${sessionStorage.getItem(
        "userToken"
      )}`
    );
    return res.data.downloadUrl;
  };

  getEditedData = async (updatedData) => {
    const params = {
      invoiceId: updatedData.invoiceId,
      updatedValue: updatedData.updatedValue,
    };
    const res = await this.axios.get(`/receivables/editable`, { params });
    return res.data;
  };

  getCustomerDetails = async (data) => {
  
    const params = {
      id: data.customerName,
    };
    const res = await this.axios.get(`/receivables/customer-details`, {
      params,
    });
    return res.data;
  };
  getMerchantDetails = async()=>{
    const res = await this.axios.get(`/receivables/merchantDetails`)
    return res.data
  }
  saveTransactionReceivable = async(data)=>{

    const res = await this.axios.post(`/receivables/save-transaction`, data);
    return res.data;
  }
  getCustomerTransaction = async (filterData) => {


    const startTimestamp =
      (filterData?.TransactionDate && filterData?.TransactionDate[0] && filterData?.TransactionDate && filterData?.TransactionDate[0] != 'Invalid Date')
        ? moment(filterData?.TransactionDate[0]).format("YYYY-MM-DD")
        : "";

    const endTimestamp =
      (filterData?.TransactionDate && filterData?.TransactionDate[1] && filterData?.TransactionDate && filterData?.TransactionDate[1] != 'Invalid Date')
        ? moment(filterData?.TransactionDate[1]).format("YYYY-MM-DD")
        : "";
    const params = {
      field: filterData.columnName,
      sort: filterData.order,
      pageNo: filterData.pageNumber,
      limit: 10,
      customerId: filterData.customerId ? filterData.customerId : "",
      startDate: startTimestamp,
      endDate:endTimestamp,
      invoiceNumber: filterData.invoiceNumber,
      type: filterData.Type,
      TransactionStatus: filterData.TransactionStatus,
      minAmount: filterData.minAmount,
      maxAmount: filterData.maxAmount,
      // TransactionTimeStamp: unixTimestamps,
      paidFor: filterData.paidFor
    };
    const res = await this.axios.get(`/receivables/transaction-list`, { params });
    return res.data;
  };

  GenerateCheckSumReceivableData = async (data) => {

    const res = await this.axios.post(`/receivables/generate-checksum`, data);
    return res.data;
  }


  GenerateSessionTokenData = async (data) => {
    if (data.amount) {
      const res = await this.axios.post(`/receivables/generate-session-token`, data);
      return res.data;
    }

  }
  GetAllCustomer = async ()=>{
    const res = await this.axios.get(`/receivables/get-all-customers`)
    return res
  }
  exportInvoice = async (transactionId)=>{
    const tenant = sessionStorage.getItem("tenantName");
    return `${apiURL}/receivables/invoice/export?transactionId=${transactionId}&tenant=${tenant}&key=${sessionStorage.getItem(
        "userToken"
      )}`;
  }
}
export default new ActualService();
