import React from "react";
import styled from "styled-components";
import { Row, Col, Dropdown } from "react-bootstrap";
import { Route_URLs } from "routesURLs";
import { Authorise } from "helpers/Authorization";
import { useState } from "react";
import Table from "ui/table";
import useDebounce from "hooks/useDebounce";

import moment from "moment";
import NumberFormat from "react-number-format";
import { ExportIcon, ReceivablesIcon } from "assets/icons.js";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import downloadPdf from "assets/download.gif";
import { UseTransactionList } from "queries/actuals";
import PrivateLayout from "layouts/organization-ui/PrivateLayout";
import Page from "ui/Page";

// import { StyledDropDown, TableSection } from "./Styles";
import customersServices from "services/customers.services";
import { FilterSection, StyledDropDown, TableSection } from "../Styles";
import actualServices from "services/actual.services";
import TransactionFilter from "screens/customer/TransactionFilter";
import Tooltip from "ui/Tooltip";

const StyledTable = styled.div`
  table {
    tr {
      &:hover {
        input {
          border: 1px solid #ffffff !important;
        }
      }
    }
    input {
      background: transparent;
      border: 0;
      color: #b5b5b5;
      text-align: center;
      width: 79px;
    }
  }
`;

export default function ReceivableTransactions() {
  const [initialPage, setinitialPage] = useState(0);

  let tokenData = sessionStorage.getItem("userToken");
  let decodedData = jwt_decode(tokenData);

  const rowClick = (e, row, editId) => {
    return null;
  };

  const [filterData, setFilterData] = useState({
    limit: "",
    searchString: "",
    deliveryDueDate: "",
    minAmount: "",
    maxAmount: "",
    bucket: "",
    pageNumber: 0,
    order: "desc",
    columnName: "TransactionTimeStamp",
    customerId: decodedData.username,
    invoiceNumber: "",
    Type: "",
  });
  const user = {
    firstName: "",
    lastName: "",
  };
  const handlePageChange = (page) => {
    filterData.pageNumber = page;
    setFilterData({ ...filterData, ...filterData });
    setinitialPage(page);
  };

  const updateFilters = (updatedFilterData) => {
    if (updatedFilterData.searchString == "") {
      handlePageChange(0);
    }
    if (filterData.searchString == "" && updatedFilterData.searchString != "") {
      handlePageChange(0);
    }
    let newFilterState = { ...filterData, ...updatedFilterData };
    setFilterData(newFilterState);
  };
  const onHeaderClick = (column) => {
    filterData.columnName = column.id;
    filterData.order = filterData.order === "desc" ? "asc" : "desc";
    let updatedFilter = { ...filterData, ...filterData };

    setFilterData(updatedFilter);
  };

  const debouncedfilterData = useDebounce(filterData, 1000);
  const {
    data: transactionData,
    isLoading: isloading,
    refetch: refetchData,
  } = UseTransactionList(debouncedfilterData);
  useEffect(() => {
    refetchData();
  }, [refetchData]);

  let transactiondata = transactionData
    ? transactionData.data.transactions
    : [];

  useEffect(() => {}, [transactionData, isloading]);
  const totalcount = transactionData ? transactionData?.data?.totalRecords : "";

  const handleDownload = async (transactionId) => {
    const url = await actualServices.exportInvoice(transactionId);

    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  function isWithin30Seconds(time) {
    const inputTime = moment(time);

    const now = moment();

    const durationInSeconds = now.diff(inputTime, "seconds");

    return Math.abs(durationInSeconds) <= 30;
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Transaction Id",
        accessor: "transactionId",
        id: "transactionId",
        width: 150,
        minWidth: 130,
        Cell: (props) => (
          <>
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{ marginRight: "5px" }}
                  onClick={(e) => {
                    handleDownload(
                      props.row.original.transactionId,
                    );
                  }}
                >
                  {props.row.original.TransactionStatus === "APPROVED" ||
                  props.row.original.TransactionStatus === "PENDING" ||
                  props.row.original.TransactionStatus === "SUCCESS" ||
                  props.row.original.TransactionStatus === "UPDATE" ? (
                    <>
                      {!isWithin30Seconds(
                        props.row.original.TransactionTimeStamp
                      ) ? (
                        <ExportIcon
                          font-size="20px"
                          style={{ marginRight: "10px" }}
                        />
                      ) : (
                        <>
                          <div data-for="txn" data-tip="Generating Invoice">
                            <img
                              src={downloadPdf}
                              alt="downloadPdf"
                              height="20px"
                              width="20px"
                            />
                          </div>{" "}
                          <ReactTooltip
                            id="txn"
                            place={"top"}
                            className="tooltip"
                          />
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </span>
                {props.row.original.transactionId}
              </div>
            </>
          </>
        ),
      },
      {
        Header: "Invoice No. / Reference No.",
        accessor: (d) => {
          return d;
        },
        id: "invoiceArray",
        width: 100,
        minWidth: 80,
        Cell: ({ value }) => {
          if (value?.invoiceArray && value?.invoiceArray?.length > 0) {
            return (
              <div>
                {value.invoiceArray.map((id, index) => (
                  <div key={index}>{id}</div>
                ))}
              </div>
            );
          } else {
            return <div>{value?.referenceNumber}</div>;
          }
        },
        disableSortBy: true,
      },
      {
        Header: "Payment Method",
        accessor: "Type",
        id: "Type",
        width: 110,
        minWidth: 80,
      },

      {
        Header: "Date",
        accessor: "TransactionTimeStamp",
        id: "TransactionTimeStamp",
        width: 100,
        minWidth: 80,
        accessor: (d) => {
          return moment(d.TransactionTimeStamp).format("MM/DD/YYYY");
        },
      },
      {
        Header: "Amount",
        id: "TotalAmount",
        width: 150,
        minWidth: 100,
        accessor: (d) => {
          return (
            <NumberFormat
              thousandSeparator={true}
              prefix={"$"}
              value={parseFloat(d.TotalAmount).toFixed(2)}
              displayType="text"
              isNumericString={true}
              decimalScale={2}
            />
          );
        },
      },

      {
        Header: "Status",
        accessor: "TransactionStatus",
        id: "TransactionStatus",
        width: 100,
        minWidth: 80,
      },
    ],
    [time]
  );

  return (
    <>
      <PrivateLayout>
        <Page>
          <Page.Header>
            <Row className="m-0">
              <Col sm={3} className="p-0">
                <StyledDropDown
                  variant="secondary"
                  align="end"
                  title="Transactions"
                >
                     <Authorise
                    showUnAuthorisedComponenet={<></>}
                    allowedRoles={[
                      "admin",
                      "simplicollect",
                      "SimpliCollect&Simplipay",
                    ]}
                  >
                    <Dropdown.Item href={Route_URLs.actuals_receivables}>
                      Receivables
                    </Dropdown.Item>
                  </Authorise>
                  <Authorise
                    showUnAuthorisedComponenet={<></>}
                    allowedRoles={[
                      "admin",
                      "simplipay",
                      "SimpliCollect&Simplipay",
                    ]}
                  >
                    <Dropdown.Item href={Route_URLs.actuals_payables}>
                      Payables
                    </Dropdown.Item>
                  </Authorise>
                  <Authorise
                    showUnAuthorisedComponenet={<></>}
                    allowedRoles={[
                      "admin",
                      "simplicollect",
                      "SimpliCollect&Simplipay",
                    ]}
                  >
                    <Dropdown.Item href={Route_URLs.actuals_collections}>
                      Collections
                    </Dropdown.Item>
                  </Authorise>

                  <Authorise
                    showUnAuthorisedComponenet={<></>}
                    allowedRoles={[
                      "admin",
                      "simplipay",
                      "SimpliCollect&Simplipay",
                    ]}
                  >
                    <Dropdown.Item href={Route_URLs.actuals_payments}>
                      Payments
                    </Dropdown.Item>
                  </Authorise>
             
                </StyledDropDown>
              </Col>
              <Col></Col>
            </Row>

          </Page.Header>
          <Page.Body>
          <Row style={{ display: "flex" }}>

            <FilterSection
              style={{
                position: "relative",
                bottom: "30px",
                margin: "4px -27px -40px",
                justifyContent: "end"
              }}
            >
              <TransactionFilter handleFilterData={updateFilters} />

              {/* <div className="icon-div">
                <a
                  onClick={async () => {
                    let link = await customersServices.getallcustomersDetails(
                      filterData
                    );
                    window.open(link, "_blank");
                  }}
                >
                  <ReceivablesIcon data-tip="Export" data-for="export" />
                  <Tooltip id="export" place="top" />
                </a>
              </div> */}
            </FilterSection>
          </Row>
            <TableSection>
              <Col className="p-0">
                <StyledTable>
                  {transactiondata?.length >= 0 && (
                    <Table
                      data={transactiondata}
                      columns={columns}
                      onHeaderClick={onHeaderClick}
                      totalCount={totalcount}
                      handlePageChange={handlePageChange}
                      initialPage={initialPage}
                      rowClick={rowClick}
                      isloading={isloading}
                    />
                  )}
                </StyledTable>
              </Col>
            </TableSection>
          </Page.Body>
        </Page>
      </PrivateLayout>
    </>
  );
}
