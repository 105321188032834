import axios from "utils/APIv2";
export const updateUser = async (userID, values) => {
  return await axios.put(`/users/${userID}`, values);
};

export const getUser = async (userID) => {
  const res = await axios.get(`/users/${userID}`);
  return res.data;
};

export const getUsers = async (page, filterData) => {
  const params = {
    departments: "true",
    page: page,
    limit: "10",
    name: filterData.username,
    status: filterData.statusArray,
    role: filterData.roleArray,
    business: filterData.businessArray,
    sortOrder: filterData.sortOrder,
    sortOn: filterData.sortOn,
  };
  const res = await axios.get(`/users`, { params: params });
  return res.data;
};

export const addUser = async (User) => {
  return await axios.post(`/users`, User);
};
export const importCSV = (file) => {
  return axios.post(`/users/import`, file);
};
export const downloadSampleImportFile = () => {
  return `https://simplicapital-p2-bucket.s3.amazonaws.com/user_import_sample.csv`;
};

export const getUserIntegrations = async () => {
  return await axios.get("/integrations");
};
export const getCustomerDetailsforSingup = async (data) => {
  return await axios.post("/findCustomer",data);
};