import React from "react";

import { useProfile } from "queries/profile";

export const Authorise = ({
  showUnAuthorisedComponenet = null,
  children,
  allowedRoles = ["admin"],
  allowedPermission = [],
  allowedOperation = [],
  onlyforthisrole=false
}) => {
  const { data: userData, isLoading } = useProfile();
  const userRole = userData?.role;
  const userPermissions = userData?.permissions;
  const active = userData?.status == "active";

  if (isLoading) return <></>;

  const possibileOperationForRole = {
    "read-write": "read-write",
    "read-only": "read-only",
  };

  if (active && userRole === "admin" && !onlyforthisrole) return <>{children}</>;

  if (active && allowedRoles.includes(userRole)) {
    if (allowedPermission.length === 0 && allowedOperation.length === 0) {
      return <>{children}</>;
    } else {
      let hasPermission = userPermissions.includes(allowedPermission);

      if (
        hasPermission &&
        possibileOperationForRole[userRole] === allowedOperation
      ) {
        return <>{children}</>;
      }
    }
  }
  // if (allowedRoles.includes(userRole)) return <>{children}</>;
  // // if (userRole === "admin") return <>{children}</>;

  // let hasPermission = permissions.includes(allowedPermission);

  // if (hasPermission && userRole === allowedOperation) {
  //   return <>{children}</>;
  // }

  if (showUnAuthorisedComponenet) return <>{showUnAuthorisedComponenet}</>;

  return (
    <p style={{ color: "white", margin: "100px", textAlign: "center" }}>
      {" "}
      401 - You are not Authorized !
    </p>
  );
};
