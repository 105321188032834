import React, { useState, useMemo, useEffect } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useExpanded,
  useRowSelect,
} from "react-table";
import styled from "styled-components";
import { SortIcon } from "assets/icons.js";
import Toggleicon from "assets/arrow-down-new.svg";
import { Table as BSTable, OverlayTrigger } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Loader from "./Loader";
import CustomScrollbars from "ui/scrollbar";
import Checkbox from "ui/Checkbox";
import Tooltip from "ui/Tooltip";
import { EditIcon, InfoIcon } from "assets/icons";
import { CustomerChildren } from "queries/customers";
import { useCallback } from "react";
import axios from "utils/APIv2";
import { Authorise } from "helpers/Authorization";
import NumberFormat from "react-number-format";

export const StyledSubRow = styled.tr`
  background-color: #636471;
  padding-left: ${(props) => props.depth * 20}px;
  color: #ffffff;
  // cursor: pointer;
`;

export const TableWrapper = styled.div`
  position: relative;
  background: #3a3b48 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  padding: 15px 25px;
  border-radius: 5px;
  min-height: 470px;
  .spinner-border.text-secondary {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .styledNoRecord {
    color: white;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: inline-table;
    top: 251px;
  }
  input[type="checkbox"] {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 2px solid #707070;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    margin-top: 3px;
    background-repeat: no-repeat;
    &:after {
      content: "✔";
      color: transparent;
      position: absolute;
      top: -35%;
      right: 0%;
      font-size: 11px;
      padding: 2px;
    }
    &:checked {
      &:after {
        content: "✔";
        color: #33c2df;
        position: absolute;
        top: -35%;
        right: 0%;
        font-size: 11px;
        padding: 2px;
      }
    }
  }
`;

const StyledCheckboxHeader = styled.div`
  margin-top: 3px;
  input {
    background-color: transparent !important;
    border: 2px solid #ffffff !important;
  }
`;

const StyledLabel = styled.span`
  font-family: Montserrat Alternates;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #909090;
  opacity: 1;
  display: inline-block;
`;

const ExpandIcon = styled.img`
  margin-right: 8px;
  transition: transform 0.3s ease;
  transform: ${(props) =>
    props.isExpanded ? "rotate(0deg)" : "rotate(-90deg)"};
`;

export const StyledTable = styled(BSTable)`
  td {
    height: 42px;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 11px;
    line-height: 25px;
    border-bottom: 1px solid gray;
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 9px;
    }
  }
  th {
    height: 42px;
    text-align: center;
    font-family: Montserrat Alternates-semiBold;
    font-size: 11px;
    line-height: 25px;
    color: #ffffff;
    @media (min-width: 768px) and (max-width: 1024px) {
      font-size: 9px;
    }
  }
  td:first-child,
  th:first-child {
    width: 5%;
    text-align: left;
  }
  td:nth-child(2) {
    text-align: left;
  }
`;

export const TableHead = styled.thead`
  tr {
    background: transparent linear-gradient(90deg, #0081c5 0%, #31c0de 100%) 0%
      0%;
    border-bottom: underline;
    @media (min-width: 768px) and (max-width: 1024px) {
      th {
        background: #0081c5;
      }
    }
    &:hover {
      background: transparent linear-gradient(90deg, #0081c5 0%, #31c0de 100%)
        0% 0% no-repeat padding-box;
      color: #ffffff;
    }
  }
  svg {
    font-size: 8px;
    margin-left: 2px;
  }
`;

export const StyledRow = styled.tr`
  background: #232631;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: #636471;
    color: #ffffff;
  }
`;

export const PaginationWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Pagination = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  ul {
    margin: 10px;
  }

  & li {
    padding: 0.2rem;
    border-radius: 7px;
    padding-bottom: 0px !important;

    a {
      padding: 0.5rem;
    }
  }

  & .active {
    background-color: #33c2df;
  }
  .previous,
  .next {
    background-color: #373743;
  }
`;

const StyledNoRecord = styled.span`
  color: white;
`;

const StyledLoader = styled.div`
  /* font-size:1em; */
`;

const ScrollWindow = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background: #3a3b48;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }
`;

function TableExpander({
  isloading,
  editId,
  columns,
  data,
  rowClick,
  onHeaderClick,
  totalCount,
  handlePageChange,
  initialPage,
  isEdit,
  updateData,
  checkbox,
  handleReminderSettings,
  Popover,
  closeModal,
  filterData,
  setChildEmailCheck,
  setParentRecord,
  parentRecord,
  childEmailCheck,
}) {
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [expandedRowData, setExpandedRowData] = useState(null);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      initialState: {
        pageSize: 1000,
        expanded: {},
      },
      columns,
      data,
      manualSortBy: true,
      editId,
      isEdit,
      updateData,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );
  const [prevData, setPrevData] = useState(data);

  useEffect(() => {
    if (data !== prevData) {
      setExpandedRowId(null);
      setExpandedRowData(null);
      setPrevData(data);
    }
  }, [data, prevData]);

  const fetchChildRecords = useMemo(
    () => async (parentId) => {
      try {
        const response = await axios.post(`/customers/childrenData`, {
          parentId: parentId,
          isOpenInvoiceCustomers: filterData.isOpenInvoiceCustomers,
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching child records:", error);
        return [];
      }
    },
    [filterData]
  );

  useEffect(() => {
    setExpandedRowId(null);
    setExpandedRowData(null);
  }, [filterData]);

  const Pagecount = Math.ceil(Math.abs(totalCount / 10));

  const handleRowClick = async (e, row, editId) => {
    const clickedColumnId = e.target.closest("td")?.cellIndex;
    const assignTemplateColumnIndex = columns.findIndex(
      (col) => col.id === "Assign Template"
    );
    if (
      clickedColumnId !== undefined &&
      clickedColumnId !== assignTemplateColumnIndex &&
      clickedColumnId !== assignTemplateColumnIndex + 1
    ) {
      if (expandedRowId === row.id) {
        setExpandedRowId(null);
        setExpandedRowData(null);
      } else {
        const childRecords = await fetchChildRecords(row.original.Id);
        setExpandedRowId(row.id);
        setExpandedRowData(childRecords.childrensData);
        const hasValidEmail = childRecords.childrensData.some(
          (child) => child.email !== null && child.email !== ""
        );
        setParentRecord(false);
        setChildEmailCheck(hasValidEmail);
      }
    }

    rowClick(e, row, editId);
  };

  const renderRow = (row, depth = 0) => {
    prepareRow(row);
    const isExpanded = row.id === expandedRowId;

    return (
      <React.Fragment key={row.id}>
        <StyledRow
          {...row.getRowProps()}
          onClick={(e) => handleRowClick(e, row, editId)}
        >
          {row.cells.map((cell, index) => (
            <td
              {...cell.getCellProps({
                style: {
                  width: cell.column.width,
                  minWidth: cell.column.minWidth,
                  maxWidth: cell.column.maxWidth,
                },
              })}
            >
              {index === 0 && (
                <ExpandIcon
                  src={Toggleicon}
                  alt="Toggle"
                  isExpanded={isExpanded}
                  style={{ margin: " 2px 5px" }}
                />
              )}
              {cell.render("Cell")}
            </td>
          ))}
        </StyledRow>

        {isExpanded && expandedRowData && (
          <>
            {expandedRowData.map((childRow, index) => (
              <StyledSubRow
                key={childRow._id}
                depth={depth + 1}
                isEven={index % 2 === 0}
              >
                {columns.map((column) => (
                  <td key={column.id}>
                    {column.id === "action" ? (
                      <Authorise
                        showUnAuthorisedComponenet={<></>}
                        allowedRoles={[
                          "admin",
                          "simplicollect",
                          "SimpliCollect&Simplipay",
                        ]}
                      >
                        <OverlayTrigger
                          trigger="click"
                          rootClose={true}
                          placement="bottom-end"
                          overlay={Popover(
                            childRow,
                            () => {}, // Placeholder for childFunc
                            handleReminderSettings,
                            closeModal,
                            childEmailCheck,
                            parentRecord
                          )}
                        >
                          <span
                            style={{
                              marginLeft: "10px",
                              color: "#33C2DF",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            Manage Settings
                          </span>
                        </OverlayTrigger>
                      </Authorise>
                    ) : column.id === "outstanding" ? (
                      <NumberFormat
                        thousandSeparator={true}
                        prefix={"$"}
                        value={Number(childRow[column.id]).toFixed(2)}
                        displayType="text"
                        isNumericString={true}
                        decimalScale={2}
                      />
                    ) : (
                      childRow[column.id]
                    )}
                  </td>
                ))}
              </StyledSubRow>
            ))}
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      <TableWrapper className="table-wrapper">
        {isloading ? (
          <Loader />
        ) : (
          <>
            <ScrollWindow>
              <StyledTable borderless {...getTableProps()}>
                <TableHead>
                  {headerGroups.map((headerGroup) => (
                    <StyledRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                            {
                              style: {
                                width: column.width,
                                minWidth: column.minWidth,
                                maxWidth: column.maxWidth,
                              },
                            }
                          )}
                          onClick={() =>
                            !column.disableSortBy && onHeaderClick(column)
                          }
                        >
                          {column.render("Header")}
                          {column?.Tooltip && (
                            <StyledLabel>
                              <InfoIcon
                                data-tip={column?.Tooltip}
                                data-for="info"
                                style={{ marginLeft: "5px" }}
                              />
                              <Tooltip place="right" id="info" />
                            </StyledLabel>
                          )}
                          {!column.disableSortBy && <span>{<SortIcon />}</span>}
                        </th>
                      ))}
                    </StyledRow>
                  ))}
                </TableHead>

                <tbody {...getTableBodyProps()}>
                  {!isloading && data.length > 0 ? (
                    <>{page.map((row) => renderRow(row))}</>
                  ) : (
                    <tr className="styledNoRecord">No Records Found</tr>
                  )}
                </tbody>
              </StyledTable>
            </ScrollWindow>

            {!isloading && data.length > 0 && (
              <PaginationWrapper>
                <Pagination>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={Math.ceil(Pagecount)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={6}
                    onPageChange={(_page) => handlePageChange(_page.selected)}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    forcePage={initialPage}
                  />
                </Pagination>
              </PaginationWrapper>
            )}
          </>
        )}
      </TableWrapper>
    </>
  );
}
export default TableExpander;
