import styled from "styled-components";
import { Modal } from "ui/Modal";
import UserForm from "./_UserForm";
import { useUpdateUser } from "mutations/updateUser";
import { ErrorMessage } from "formik";
import { useState } from "react";
import { useUser } from "queries/user";

const StyledModal = styled.div`
  .modal-header {
    padding: 1rem 1rem;
    border-bottom: 1px solid #707070;
    margin: 10px;
    h1 {
      font-family: Montserrat Alternates-Bold;
      text-align: left;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }
  }
`;

export default function EditUserModal({
  show,
  user,
  onHide,
  refetch,
  userRolesData,
}) {
  const userUpdate = useUpdateUser();
  const [errorMessage, setErrorMessage] = useState("");
  const { data: permissions, isLoading } = useUser(user._id);

  const onSuccess = (values) => {
    const userId = user._id;

    let serverUser = {
      givenName: values.firstName,
      familyName: values.lastName,
      phoneNumber: values.mobile,
      email: values.email,
      role: values.access,
      status: values.status,
      permissions:
        values.access === "admin" || values.access === "read-only"
          ? []
          : values.permissions,
    };

    userUpdate
      .mutateAsync({ userId, values: serverUser })
      .then(() => {
        refetch();
        onHide();
        setErrorMessage(null);
      })
      .catch(function (error) {
        if (error.response) {
          setErrorMessage(error.response.data.message);
        }
      });
  };

  return (
    <Modal show={show} size="lg" centered onHide={onHide}>
      <StyledModal>
        <Modal.Header closeButton>
          <h1>Edit User</h1>
        </Modal.Header>
        <Modal.Body>
          <UserForm
            user={{
              _id: user._id,
              firstName: user.givenName,
              lastName: user.familyName,
              mobile: user.phoneNumber,
              email: user.email,
              access: user.role,
              status: user.status,
              permissions: permissions?.permissions.map((permission) => {
                return permission;
              }),
              // business: user.business?.map((department) => department.id),
            }}
            onSuccess={onSuccess}
            errorMessage={errorMessage}
            userRolesData={userRolesData}
          />
        </Modal.Body>
      </StyledModal>
    </Modal>
  );
}
